import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import CalendarDatePicker from '../CalendarDatePicker';
import { fetch_download_care_coordinator, fetch_reports_care_coordinator, getFileLink } from '../../../services/medengage/Service';
import { LoaderLoader, formatDate } from '../../utils/CommonLibrary';
import { decryptData } from '../../utils/Utils';

function ContentCNReportsTable() {

    const storedDates = localStorage.getItem('selectedDates') ? JSON.parse(decryptData(localStorage.getItem('selectedDates'))) : '';

    const [startDate, setStartDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
    );
    const [endDate, setEndDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
    );

    const [searchQuery, setSearchQuery] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('All');
    const [selectedReport, setSelectedReport] = useState('All');
    const [dateRange, setDateRange] = useState([null, null]);
    //const [startDate, endDate] = dateRange;
    const [reportData, setReportData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');

    const today = new Date();
    const sevenDaysAgo = new Date();
    // sevenDaysAgo.setDate(today.getDate() - 7);

    const handleDateChange = ({ start, end }) => {
        setStartDate(start);
        setEndDate(end);
    };
    
    
    useEffect(() => {

        fetchCareCoordinatorData();
        
    }, []);

    const downloadLink = async (reportId)=>{
        try{
            setIsLoading(true);
             let resp = await getFileLink(reportId);
             let downloadUrl = resp.data.data.url;
             if (downloadUrl) {
                const link = document.createElement('a');
                const filename = `report_${reportId}.xls`;
                link.href = downloadUrl;
                link.setAttribute('download', filename); // Specify the filename here if necessary
                document.body.appendChild(link);
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
              } else {
                console.error('Download URL not found in response');
              }
        }catch (error) {
            console.error(error);
        } 
        finally {
              setIsLoading(false);  // Will be called regardless of success or failure
        }
    }

    const columns = [
        {
            name: 'Generated By',
            selector: row => row.generatedBy,
            sortable: true,
        },
        {
            name: 'Report Type',
            selector: row => row.reportType,
            sortable: true,
        },
        
        {
            name: 'Start Date',
            selector: row => row.startDate,
            sortable: true,
        },
        {
            name: 'End Date',
            selector: row => row.endDate,
            sortable: true,
        },
        {
            name: 'Generated On',
            selector: row => row.createdDate,
            sortable: true,
        },
        {
            name: 'Download Reports',
            cell: row => (
                row.status == "Completed" ?
                <div>
                    <span onClick={()=>downloadLink(row.reportId)} style={{cursor:'pointer'}}> <i className="bi bi-download"></i></span>
                </div> :
                <span>{row.status}</span>
            ),
        },
    ];


    const fetchCareCoordinatorData = async() => {
        try{
            setIsLoading(true);
                let response = await fetch_reports_care_coordinator();
                    if(response.data.code === 200){
                        setReportData(response.data.data);
                    }
                }catch (error) {
                    console.error(error);
                  } 
                  finally {
                      setIsLoading(false);  // Will be called regardless of success or failure
                  }
            }
    const data = reportData;

      
    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };
    const filterData = data.filter(user => {
        
        const matchesSearchQuery = 
        (typeof user.generatedBy === 'string' && user.generatedBy.toLowerCase().includes(searchQuery.toLowerCase()));
        
        return matchesSearchQuery;
    });
    
    const genrateReport = async () =>{
        setErrorMsg('');
        if(selectedReport == 'All'){
            setErrorMsg('Select Report type');
            return false;
        }
        try {
            setIsLoading(true); // show loader
            let sdate = formatDate(startDate);
            let edate = formatDate(endDate);
            let response = await fetch_download_care_coordinator(sdate,edate,selectedReport);
            console.log('newresponse',response.data);
            if(response.data.code === 200){
                if(response.data.data.status == 1){
                    fetchCareCoordinatorData();
                    setMessage(response.data.data.msg);
                    setTimeout(() => setMessage(false), 3000);
                }else{
                    setError(response.data.data.msg);
                    setTimeout(() => setError(false), 3000);
                }
                
            }
            
        } catch (error) {
          console.error(error);
        } 
        finally {
            setIsLoading(false);  // Will be called regardless of success or failure
        }
    }
    

    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md-4 px-1'>
                        <select
                            value={selectedReport}
                            onChange={e => setSelectedReport(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="">--Select Report:--</option>
                            <option value="careNavigatorbreaksplan">CareNavigator Breaks Plan</option>
                            <option value="callhistory">Call History</option>
                            <option value="servicesreport">Service Report</option>
                            <option value="hhc">Home Care Request Report</option>
                            <option value="hbv">Home Based Vaccine Report</option>
                            <option value="medication">Medication Request Report</option>
                            <option value="lab">Lab Request Report</option>
                            <option value="memberdata">Member Data</option>
                            
                        </select>
                        {errorMsg && <div className="text-danger">{errorMsg}</div>}
                    </div>
                    <div className='col-md px-1'></div>
                    <div className='col-md-3 px-1'>
                        <CalendarDatePicker startDate={startDate} endDate={endDate} onDateChange={handleDateChange}  />
                    </div>
                    <div className='col-md-auto pe-1'>
                        <button type='button' className='btn btn-primary add-new-program-btn' onClick={genrateReport}>Generate
                        </button>
                    </div>
                    <div className={`${error != "" ? "errorDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{error}</div>
                    <div className={`${message != "" ? "messageDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{message}</div>
                </div>
            </div>
            <LoaderLoader isLoading={isLoading} />
            <DataTable
                columns={columns}
                data={filterData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />
        </>
    );
}

export default ContentCNReportsTable;
